import {
  type BlockIdentifier,
  getBlockSettingsSingleton,
  isBlockIdentifier
} from 'cadenza/map/block-settings';
import { isThemeFeature, type ThemeFeatureName } from 'cadenza/theming-api';
import { assert } from 'cadenza/utils/custom-error';
import type { FeatureName } from 'cadenza/model/feature-name';
import { isFeatureName } from 'cadenza/model/feature-name';

const UI_FEATURE_NAMES = [
  /**
   * @see Published {@link https://jira.disy.net/browse/CADENZA-33416} Customers may use this to
   * deactivate the designer
   */
  'workbook-design', // allow showing designer
  'workbook-new-view', // show new view button
  'workbook-data-browser', // allow showing data browser
  'workbook-data-view-content', // allows to edit data-view content in the designer
  'workbook-access-settings', // show access settings button
  'workbook-more-actions', // show more ... button in header
  'workbook-snapshots', // show buttons for creating and selecting snapshots
  'workbook-save', // show save/save and publish/save as
  'workbook-worksheet-menu', // show drop-down menu for worksheets
  'workbook-select-version', // allow selecting the version when clicking the versions tag
  'workbook-view-titlebar', // show the title bar of views
  'workbook-view-titlebar-buttons', // show the title bar buttons of views
  'workbook-analysis-context', // enable the analysis context
  'workbook-analysis-context-force-open', // always show the analysis context, do not allow closing
  'workbook-condition-management', // allows to edit/add/remove conditions in views and global analysis context
  /**
   * @see Published {@link https://jira.disy.net/browse/CADENZA-33416}  Customers may use this to
   * deactivate editing of WB layout/design
   */
  'workbook-view-management', // allows to edit/add/remove views, data views
  'workbook-workbook-management', // allows to manage a workbook, create/duplicate/edit worksheet, click on version tag,
  'workbook-layout-management', // allows to edit workbook layout
  'report-management',
  'import-management',
  'spatial-condition-management',
  'sketch-layer-management'
] as const;

export type UiFeatureName = typeof UI_FEATURE_NAMES[number];
export type WorkbookUiFeatureName = UiFeatureName & `workbook-${string}`;

export type AllFeatures = FeatureName | UiFeatureName | ThemeFeatureName | BlockIdentifier;

interface FeatureTuple {
  feature?: FeatureName;
  uiFeature?: UiFeatureName;
  themeFeature?: ThemeFeatureName;
  block?: BlockIdentifier;
}

const FEATURE_SYNONYMS: FeatureTuple[] = [
  {
    themeFeature: 'classic-map-add-layer-shapefile',
    block: 'addShapefile'
  }
];

/**
 * The keys must not be of type FeatureName, because the dependencies
 * between the ICadenzaFeatures are modeled already in the backend.
 */
type DependingFeature = Exclude<AllFeatures, FeatureName>;
type FeatureDependencies = { [key in DependingFeature]?: AllFeatures[]; };

/**
 * Defines which features depend on others.
 * When adding new dependent features here, please check that there are not loops - features
 * should not depend on each other.
 * Example:
 * 'workbook-edit': ['workbook-management', 'application-admin'] => 'workbook-edit' feature is available only when 'workbook-management' and 'application-admin' are available
 */
const FEATURE_DEPENDENCIES: FeatureDependencies = {
  'import-management': [ 'workbook-workbook-management', 'WORKBOOK_IMPORT' ],
  'report-management': [ 'workbook-workbook-management', 'workbook-view-management', 'EDIT_REPORT' ],
  'sketch-layer-management': [ 'workbook-view-management', 'CREATE_SKETCH_LAYER' ],
  'spatial-condition-management': [ 'workbook-view-management', 'WORKBOOK_EDIT_SPATIAL_CONDITION' ],
  'workbook-access-settings': [ 'workbook-workbook-management' ],
  'workbook-data-view-content': [ 'workbook-design' ],
  'workbook-layout-management': [ 'workbook-design' ],
  'workbook-new-view': [ 'workbook-view-management' ],
  'workbook-save': [ 'workbook-workbook-management' ],
  'workbook-select-version': [ 'workbook-workbook-management' ],
  'workbook-snapshots': [ 'WORKBOOK_SNAPSHOTS_USE' ],
  'workbook-view-management': [ 'workbook-design' ],
  'workbook-workbook-management': [ 'workbook-design' ],
  'workbook-worksheet-menu': [ 'workbook-workbook-management' ]
};

/**
 * Checks the availability of a Cadenza feature for the current user.
 *
 * The feature must be enabled on platform level and can be disabled on page, theme or request level.
 * Unfortunately, the feature names are different on each level, and we cannot change that, because
 * some are published. So we also check the synonyms of the given feature name. If the feature
 * depends on other features, then all other features must be available too.
 *
 * NOTE: Doesn't check workbook features - also in dependencies workbook features are omitted.
 * If you are in workbook, then isWorkbookFeatureAvailable is preferred unless you have a good
 * reason to not check workbook feature dependencies.
 *
 * @param featureName - The name of the feature to check (on any level)
 * @return Whether the feature is available.
 */
export function isFeatureAvailable (featureName: AllFeatures): boolean {
  assert(!isWorkbookFeature(featureName), 'Cannot check workbook feature with a isFeatureAvailable. Use isWorkbookFeatureAvailable instead.');

  return isSingleFeatureAvailable(featureName)
      && getDependentFeatures(featureName)
        .filter(f =>  !isWorkbookFeature(f))
        .every(f => isFeatureAvailable(f));
}

export function getDependentFeatures (featureName: AllFeatures) {
  return isFeatureName(featureName) ? [] : (FEATURE_DEPENDENCIES[featureName] ?? []);
}

/**
 * Checks if feature is available without checking dependencies. Should always be a private function.
 * @param featureName
 */
export function isSingleFeatureAvailable (featureName: AllFeatures) {
  const feature = getFeatureTuple(featureName);

  // Assertion, because this is called also from JS code.
  assert(Object.values(feature).some(v => v != null), `Unknown feature "${featureName}"`);

  return isFeatureAvailableInPlatform(feature)
    && isFeatureAvailableInPage(feature)
    && isFeatureAvailableInTheme(feature)
    && isFeatureAvailableInRequest(feature);
}

function getFeatureTuple (featureName: AllFeatures): FeatureTuple {
  return FEATURE_SYNONYMS.find(feature => Object.values(feature).includes(featureName)) ?? {
    feature: isFeatureName(featureName) ? featureName : undefined,
    uiFeature: isUiFeature(featureName) ? featureName : undefined,
    themeFeature: isThemeFeature(featureName) ? featureName : undefined,
    block: isBlockIdentifier(featureName) ? featureName : undefined
  };
}

export function isUiFeature (featureName: string): featureName is UiFeatureName {
  return UI_FEATURE_NAMES.includes(featureName as never);
}

export function isWorkbookFeature (feature: string): feature is WorkbookUiFeatureName {
  return isUiFeature(feature) && feature.startsWith('workbook');
}

function isFeatureAvailableInRequest ({ block: blockIdentifier }: FeatureTuple) {
  const blockSettings = getBlockSettingsSingleton();
  return blockIdentifier === undefined
    || !blockSettings.isBlockSettingPresent(blockIdentifier)
    || blockSettings.isBlockEnabled(blockIdentifier);
}

function isFeatureAvailableInTheme ({ themeFeature: featureName }: FeatureTuple) {
  return featureName === undefined || window.Disy.theme.features[featureName] !== false;
}

function isFeatureAvailableInPage ({ uiFeature: featureName }: FeatureTuple) {
  return featureName === undefined || !window.Disy.disabledUiFeatures.includes(featureName);
}

function isFeatureAvailableInPlatform ({ feature: featureName }: FeatureTuple) {
  return featureName === undefined || (window.Disy.availableFeatures?.includes(featureName) ?? false);
}
